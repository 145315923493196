<template>
  <div class="flex items-center">
    <app-button
      v-if="resource.authorizations.approve"
      feature="confirm"
      emphasis="low"
      hide-text
      :hide-icon="false"
      class="mx-1"
      @click="handleApprove"
    />

    <app-button
      v-if="resource.authorizations.decline"
      feature="close"
      emphasis="low"
      hide-text
      :hide-icon="false"
      class="mx-1"
      @click="handleDecline"
    />

    <app-button
      v-if="resource.authorizations.remove"
      feature="delete"
      emphasis="low"
      hide-text
      :hide-icon="false"
      class="mx-1"
      @click="handleRemove"
    />

    <app-button
      v-if="resource.authorizations.cancel"
      feature="cancel"
      emphasis="low"
      hide-text
      :hide-icon="false"
      class="mx-1"
      @click="handleCancel"
    />

    <app-button
      v-if="resource.authorizations.feature"
      emphasis="low"
      :icon-props="{
        library: 'fontawesome',
        name: 'star',
        iconStyle: 'regular',
      }"
      hide-text
      :hide-icon="false"
      class="mx-1"
      @click="handleFeature"
    />
  </div>

  <!-- Decline modal -->
  <app-overlay
    :show="declineConfirmModalDisplayed"
    @clicked="handleDeclineCancel"
  >
    <app-modal @closed="handleDeclineCancel">
      <app-confirm
        confirm-color="red"
        @cancelled="handleDeclineCancel"
        @confirmed="handleDeclineConfirm"
      >
        <p>
          {{ declineConfirmText }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>

  <!-- Approve modal -->
  <app-overlay
    :show="approveConfirmModalDisplayed"
    @clicked="handleApproveCancel"
  >
    <app-modal @closed="handleApproveCancel">
      <app-confirm
        @cancelled="handleApproveCancel"
        @confirmed="handleApproveConfirm"
      >
        <p>
          {{ approveConfirmText }}
        </p>

        <p v-if="approveConfirmChangeInstitutionText">
          {{ approveConfirmChangeInstitutionText }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>

  <!-- Remove modal -->
  <app-overlay
    :show="removeConfirmModalDisplayed"
    @clicked="handleRemoveCancel"
  >
    <app-modal @closed="handleRemoveCancel">
      <app-confirm
        confirm-color="red"
        @cancelled="handleRemoveCancel"
        @confirmed="handleRemoveConfirm"
      >
        <p>
          {{ t('institutions_employees.remove.confirm', { user_name: displayName(user) }) }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>

  <!-- Cancel modal -->
  <app-overlay
    :show="cancelConfirmModalDisplayed"
    @clicked="handleCancelCancel"
  >
    <app-modal @closed="handleCancelCancel">
      <app-confirm
        confirm-color="red"
        @cancelled="handleCancelCancel"
        @confirmed="handleCancelConfirm"
      >
        <p>
          {{ cancelConfirmText }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>

  <!-- Feature modal -->
  <app-overlay
    :show="featureConfirmModalDisplayed"
    @clicked="handleFeatureCancel"
  >
    <app-modal @closed="handleFeatureCancel">
      <app-confirm
        @cancelled="handleFeatureCancel"
        @confirmed="handleFeatureConfirm"
      >
        <p class="font-bold">
          {{ t('institutions_employees.feature.confirm', { user_name: displayName(user) }) }}
        </p>

        <p class="text-sm my-2">
          {{ t('institutions_employees.feature.folder') }}
        </p>

        <p class="text-sm">
          {{ t('institutions_employees.feature.only_one') }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import {
  declineInstitutionsEmployees,
  approveInstitutionsEmployees,
  removeInstitutionsEmployees,
  cancelInstitutionsEmployees,
  featureInstitutionsEmployees,
} from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'
import { displayName } from '@shared/helpers/user'
import useInstitutionEmployee from '@shared/hooks/resources/institutionEmployee'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Display texts
  showLabels: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'declined',
  'approved',
  'removed',
  'canceled',
  'featured',
])

const store = useStore()
const { t } = useI18n()
const {
  user,
} = useInstitutionEmployee(props)

// ---------- ACTIONS ----------

const isUserActions = computed(() => (
  user.value.id === props.resource.relationships.user.id
))

const approveConfirmText = computed(() => (
  isUserActions.value
    ? t('institutions_employees.approve.confirm.employee')
    : t('institutions_employees.approve.confirm.employer', { user_name: displayName(user) })
))

const approveConfirmChangeInstitutionText = computed(() => (
  isUserActions.value && user.value?.attributes.point_of_interest_title
    ? t('institutions_employees.approve.confirm.change_institution')
    : null
))

const declineConfirmText = computed(() => (
  isUserActions.value
    ? t('institutions_employees.decline.confirm.employee')
    : t('institutions_employees.decline.confirm.employer', { user_name: displayName(user) })
))

const cancelConfirmText = computed(() => (
  isUserActions.value
    ? t('institutions_employees.cancel.confirm.employee')
    : t('institutions_employees.cancel.confirm.employer', { user_name: displayName(user) })
))

// ---------- DECLINE ----------

const declineConfirmModalDisplayed = ref(false)

function handleDecline() {
  declineConfirmModalDisplayed.value = true
}

function handleDeclineConfirm() {
  declineConfirmModalDisplayed.value = false
  decline()
}

function handleDeclineCancel() {
  declineConfirmModalDisplayed.value = false
}

function decline() {
  store.commit('spinner/ENABLE')

  declineInstitutionsEmployees(props.resource.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('institutions_employees.decline.success'),
          type: 'success',
        },
      )
      emits('declined')
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}

// ---------- APPROVE ----------

const approveConfirmModalDisplayed = ref(false)

function handleApprove() {
  approveConfirmModalDisplayed.value = true
}

function handleApproveConfirm() {
  approveConfirmModalDisplayed.value = false
  approve()
}

function handleApproveCancel() {
  approveConfirmModalDisplayed.value = false
}

function approve() {
  store.commit('spinner/ENABLE')

  approveInstitutionsEmployees(props.resource.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('institutions_employees.approve.success'),
          type: 'success',
        },
      )
      emits('approved')
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}

// ---------- REMOVE ----------

const removeConfirmModalDisplayed = ref(false)

function handleRemove() {
  removeConfirmModalDisplayed.value = true
}

function handleRemoveConfirm() {
  removeConfirmModalDisplayed.value = false
  remove()
}

function handleRemoveCancel() {
  removeConfirmModalDisplayed.value = false
}

function remove() {
  store.commit('spinner/ENABLE')

  removeInstitutionsEmployees(props.resource.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('institutions_employees.remove.success'),
          type: 'success',
        },
      )
      emits('removed')
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}

// ---------- FEATURE ----------

const featureConfirmModalDisplayed = ref(false)

function handleFeature() {
  featureConfirmModalDisplayed.value = true
}

function handleFeatureConfirm() {
  featureConfirmModalDisplayed.value = false
  feature()
}

function handleFeatureCancel() {
  featureConfirmModalDisplayed.value = false
}

function feature() {
  store.commit('spinner/ENABLE')

  featureInstitutionsEmployees(props.resource.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('institutions_employees.feature.success'),
          type: 'success',
        },
      )
      emits('featured')
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}

// ---------- CANCEL ----------

const cancelConfirmModalDisplayed = ref(false)

function handleCancel() {
  cancelConfirmModalDisplayed.value = true
}

function handleCancelConfirm() {
  cancelConfirmModalDisplayed.value = false
  cancel()
}

function handleCancelCancel() {
  cancelConfirmModalDisplayed.value = false
}

function cancel() {
  store.commit('spinner/ENABLE')

  cancelInstitutionsEmployees(props.resource.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('institutions_employees.cancel.success'),
          type: 'success',
        },
      )
      emits('canceled')
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}
</script>
