<template>
  <div v-if="resource?.relationships?.broadcast_page">
    <app-section-title
      :title="t('broadcast.ui.titles.broadcast_page')"
      font-size="text-xl"
      class="mb-3.5"
    />

    <div class="mb-4">
      <copy-to-clipboard
        v-if="resource"
        :url="resource?.relationships?.broadcast_page?.attributes.url"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import CopyToClipboard from '@extranet/components/resources/broadcast_page/CopyToClipboard.vue'

const { t } = useI18n()

defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})
</script>
