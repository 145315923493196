<template>
  <app-button
    :label="t('common.qr_code')"
    emphasis="low"
    font-size="sm"
    display-classes="block"
    class="ml-auto"
    @click="displayQRCode"
  />

  <app-overlay
    :show="QRCodeDisplayed"
    @clicked="hideQRCode"
  >
    <app-modal
      @closed="hideQRCode"
    >
      <traveller-folder-qr-code
        :url="poi?.resource.attributes.quick_traveller_folder_url"
      />

      <invitation-link
        :link="poi?.resource.attributes.quick_traveller_folder_url"
        :hint-title="t('invitations.texts.invite_link.instructions')"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import AppButton from '@shared/components/ui/AppButton.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import InvitationLink from '@extranet/components/resources/invitation/InvitationLink.vue'
import TravellerFolderQrCode from '@extranet/components/resources/traveller_folder/TravellerFolderQrCode.vue'

defineProps({
  // JSON API resource
  poi: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()

// ---------- QR Code ----------

const QRCodeDisplayed = ref(false)

function displayQRCode() {
  QRCodeDisplayed.value = true
}

function hideQRCode() {
  QRCodeDisplayed.value = false
}
</script>
