<template>
  <teleport-wrapper to="#mainTitle">
    {{ title }}
  </teleport-wrapper>

  <router-view />
</template>

<script setup>
import { computed } from 'vue'
import useInstitution from '@extranet/hooks/institution'

// ---------- TITLE ----------

const { institutionNavbarTitle } = useInstitution()

const title = computed(() => (
  institutionNavbarTitle()
))
</script>
