<template>
  <div class="mb-5 flex justify-between items-start md:items-center flex-col md:flex-row max-w-sm md:max-w-full">
    <!-- Search -->
    <form-control-text
      name="points_of_interest_search"
      :placeholder="t('points_of_interest.index.search')"
      :icon-props="{ library: 'coolicon', name: 'search', classes: ['form-icon-theme'], size: 'text-2xl' }"
      class="max-w-sm w-full flex-1 mb-3 md:mb-0"
      @changed="handleSearch"
    />
  </div>

  <!-- Filters -->
  <app-filters
    class="my-4"
    :display-filters="hasActiveFilters"
  >
    <institutions-filters
      @filters-applied="handleFiltersApplied"
    />
  </app-filters>

  <!-- Results -->
  <div
    v-if="!loading"
  >
    <template
      v-if="pointsOfInterestList.length > 0"
    >
      <app-card-item
        v-for="(pointOfInterestProps, i) in pointsOfInterestList"
        :key="i"
        class="my-4 p-4 flex items-center justify-between"
      >
        <point-of-interest-card
          v-bind="pointOfInterestProps"
          :with-link="false"
          hide-actions
          class="flex-1-min-w-0 cursor-pointer"
          @click="displayInstitutionModal(pointOfInterestProps.resource.id)"
        />

        <app-button
          :label="t('common.actions.request_to_join')"
          emphasis="low"
          @click="handleInstitutionSelected(pointOfInterestProps.resource)"
        />
      </app-card-item>
    </template>

    <p
      v-else
      class="mt-12 text-lg text-center font-bold"
    >
      {{ t('points_of_interest.index.no_results') }}
    </p>
  </div>

  <template
    v-else
  >
    <point-of-interest-card-empty
      v-for="i in pointsOfInterestList.length === 0 ? 3 : pointsOfInterestList.length"
      :key="i"
      class="my-4"
    />
  </template>

  <!-- Navigation buttons -->
  <div
    v-if="!loading"
    class="flex items-center justify-center mt-8"
  >
    <app-button
      v-if="previousPageAvailable"
      :label="t('pagination.previous_alt')"
      emphasis="low"
      @click="handlePreviousPage"
    />

    <span
      v-if="previousPageAvailable && nextPageAvailable"
      class="mx-4"
    >
      &bull;
    </span>

    <app-button
      v-if="nextPageAvailable"
      :label="t('pagination.next_alt')"
      emphasis="low"
      @click="handleNextPage"
    />
  </div>
</template>

<script setup>
import {
  onMounted,
  ref,
} from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { isNil } from 'lodash'

import {
  fetchPointsOfInterest,
} from '@shared/http/api'
import PointOfInterestCard from '@extranet/components/resources/point_of_interest/PointOfInterestCard.vue'
import PointOfInterestCardEmpty from '@extranet/components/resources/point_of_interest/PointOfInterestCardEmpty.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppFilters from '@extranet/components/ui/AppFilters.vue'
import FormControlText from '@shared/components/form/controls/FormControlText.vue'
import InstitutionsFilters from '@extranet/components/resources/institution_employee/request_form/institutions/InstitutionsFilters.vue'
import useForm from '@shared/hooks/form/form'
import useFilters from '@shared/hooks/filters'

const props = defineProps({
  // How much POIs
  // displayed per page
  perPage: {
    type: Number,
    default: 12,
  },
})

const emits = defineEmits([
  'selected',
])

const store = useStore()
const { t } = useI18n()

// ---------- POINTS OF INTEREST ----------

const nextPageAvailable = ref(false)
const previousPageAvailable = ref(false)
const pointsOfInterestList = ref([])
const loading = ref(true)

function fetchPois() {
  loading.value = true

  const params = {
    'page': page.value,
    'search': searchKeywords.value,
    'mode': 'index',
    'source': 'extranet',
    'filters': allFieldsToSubmit.value,
    'per_page': props.perPage,
  }

  fetchPointsOfInterest(params)
    .then((response) => {
      const newPointsOfInterestList = response.data.data?.map((pointOfInterest) => ({
        resource: pointOfInterest,
      })) ?? []

      // Replace the old results with the new ones
      pointsOfInterestList.value = newPointsOfInterestList

      // Check if navigation available
      previousPageAvailable.value = !isNil(response.data.links?.prev)
      nextPageAvailable.value = !isNil(response.data.links?.next)
    })
    .finally(() => {
      loading.value = false
    })
}

// ---------- SEARCH ----------

const typingTimer = ref(0)
const searchKeywords = ref(null)

function handleSearch(value) {
  // Wait a little before emit the 'searched event'
  // it allows user to finish typing, and avoid to trigger an api call at each keystroke
  if (typingTimer.value) {
    clearTimeout(typingTimer.value)
  }
  typingTimer.value = setTimeout(() => {
    resetPage()
    searchKeywords.value = value
    fetchPois()
  }, 400)
}

// ---------- PAGINATION ----------

const page = ref(1)

function resetPage() {
  page.value = 1
}

function handlePreviousPage() {
  page.value -= 1
  fetchPois()
}

function handleNextPage() {
  page.value += 1
  fetchPois()
}

// ---------- FILTERS ----------

const {
  allFieldsToSubmit,
} = useForm(props, {
  initialValues: store.state.filters.pointsOfInterest,
  formName: 'pointsOfInterestIndexFiltersForm',
})

const {
  hasActiveFilters,
} = useFilters('pointsOfInterest')

function handleFiltersApplied() {
  resetPage()
  fetchPois()
}

// ---------- POI MODAL ----------

function displayInstitutionModal(id) {
  store.commit('modals/SET_PROPERTIES', {
    name: 'point_of_interest_show',
    display: true,
    id,
  })
}

// ---------- SELECTION ----------

function handleInstitutionSelected(resource) {
  emits('selected', resource)
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  // Filters' form-groups are rendered in child component
  // So must wait onMounted lifecycle hook to fetch resources with filters
  fetchPois()
})
</script>
