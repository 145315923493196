<template>
  <div v-if="!loading">
    <app-section-title
      title="Documents"
      font-size="text-xl"
      class="mb-3.5"
    />

    <div
      class="bg-white rounded-2lg shadow-lg shadow-theme-500/10 p-4 h-fit mb-4"
    >
      <div class="flex justify-between items-center">
        <div class="mr-3">
          <h4 class="font-bold text-lg">
            {{ t('documents.qrcode.title') }}
          </h4>

          <p class="text-sm">
            {{ t('documents.qrcode.description') }}
          </p>
        </div>

        <div>
          <a
            v-if="qrCodeSrc"
            class="
              bg-gradient-to-br from-theme-500 to-theme-700 hover:from-theme-600 hover:to-theme-800 text-white
              shadow-lg shadow-theme-500/10
              py-2 px-5 mx-auto
              font-semibold text-base whitespace-nowrap
              block justify-center items-center
              duration-300 rounded-full
            "
            :href="qrCodeSrc"
            :download="t('documents.qrcode.filename')"
          >
            {{ t('common.actions.download') }}
          </a>
        </div>

        <!-- QR Code -->
        <vue-qrcode
          v-show="false"
          ref="qrCodeRef"
          tag="img"
          :value="store.state.auth?.user.relationships.point_of_interest?.attributes.quick_traveller_folder_url"
          :options="{ width: 500 }"
          class="mx-auto w-44"
          @ready="adaptQrCodeSrc"
        />
      </div>
    </div>

    <div
      class="bg-white rounded-2lg shadow-lg shadow-theme-500/10 p-4 h-fit mb-4"
    >
      <div class="flex justify-between items-center">
        <div class="mr-3">
          <h4 class="font-bold text-lg">
            {{ t('documents.display.title') }}
          </h4>

          <p class="text-sm">
            {{ t('documents.display.description') }}
          </p>
        </div>

        <div>
          <app-button
            :label="t('common.actions.download')"
            display-classes="block"
            class="mx-auto"
            @click="handleDisplayDownload"
          />
        </div>
      </div>
    </div>

    <!-- PDF download modal -->
    <app-overlay
      :show="loadingModalDisplayed"
      @clicked="handleLoadingClose"
    >
      <app-modal @closed="handleLoadingClose">
        <app-spinner
          class="text-theme-500 mb-2"
          size="2xl"
        />

        <p>
          {{ t('pdf.common.generating') }}
        </p>

        <p class="mb-4">
          {{ t('pdf.common.waiting') }}
        </p>
      </app-modal>
    </app-overlay>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import { downloadPdfDisplayA6 } from '@shared/http/api'
import usePdf from '@shared/hooks/pdf'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'
import VueQrcode from '@chenfengyuan/vue-qrcode'

const store = useStore()
const { t } = useI18n()
const { downloadPdf } = usePdf()

const loading = ref(false)

// ---------- DOWNLOAD ----------

const loadingModalDisplayed = ref(false)

function handleDisplayDownload() {
  // Loading modal
  handleLoadingDisplay()

  downloadPdfDisplayA6()
    .then((response) => {
      downloadPdf(response.data, 'display_a6')
    })
    .finally(() => {
      handleLoadingClose()
    })
}

function handleLoadingDisplay() {
  loadingModalDisplayed.value = true
}

function handleLoadingClose() {
  loadingModalDisplayed.value = false
}

// ---------- QR CODE ----------

// QR Code image source
const qrCodeSrc = ref()

// Adapt QR Code image source
function adaptQrCodeSrc(item) {
  qrCodeSrc.value = item.src
}
</script>
