<template>
  <ul>
    <li>
      <span class="text-sm font-semibold">
        {{ capitalize(t('validation.attributes.requested_at')) }} :
      </span>

      {{ requestedAt }}
    </li>

    <template v-if="isOwner">
      <li v-if="approvedAt">
        <span class="text-sm font-semibold">
          {{ capitalize(t('validation.attributes.approved_at')) }} :
        </span>

        {{ approvedAt }}
      </li>

      <li v-if="declinedAt">
        <span class="text-sm font-semibold">
          {{ capitalize(t('validation.attributes.declined_at')) }} :
        </span>

        {{ declinedAt }}
      </li>

      <li v-if="canceledAt">
        <span class="text-sm font-semibold">
          {{ capitalize(t('validation.attributes.canceled_at')) }} :
        </span>

        {{ canceledAt }}
      </li>

      <li v-if="quitedAt">
        <span class="text-sm font-semibold">
          {{ capitalize(t('validation.attributes.quitted_at')) }} :
        </span>

        {{ quitedAt }}
      </li>
    </template>
  </ul>
</template>

<script setup>
import { computed } from 'vue'
import { capitalize } from 'lodash'
import { useI18n } from 'vue-i18n'

import useDatetime from '@shared/hooks/datetime'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Table mode
  isOwner: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()

// ---------- DATES ----------

const { formatDateShort } = useDatetime()

function getDate(name) {
  return props.resource?.attributes[name]
    ? formatDateShort(props.resource?.attributes[name])
    : null
}

const requestedAt = computed(() => (
  getDate('created_at')
))

const approvedAt = computed(() => (
  getDate('approved_at')
))

const declinedAt = computed(() => (
  getDate('declined_at')
))

const canceledAt = computed(() => (
  getDate('canceled_at')
))

const quitedAt = computed(() => (
  getDate('removed_at')
))
</script>
