<template>
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <!-- Display page depending on permissions -->
  <institutions-employee
    v-if="institutionAccess"
  />

  <institutions-manager
    v-if="institutionsAccess"
  />
</template>

<script setup>
import { computed } from 'vue'

import breadcrumbInstitution from '@extranet/breadcrumbs/institution'
import useAccess from '@extranet/hooks/access'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import InstitutionsEmployee from '@extranet/components/institutions/InstitutionsEmployee.vue'
import InstitutionsManager from '@extranet/components/institutions/InstitutionsManager.vue'

// ---------- ACCESS ----------

const {
  institutionAccess,
  institutionsAccess,
} = useAccess()

// ---------- BREADCRUMBS ----------

const { dashboard } = breadcrumbInstitution()

const breadcrumbs = computed(() => (
  dashboard()
))
</script>
