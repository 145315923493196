<template>
  <institutions-index
    @selected="handleInstitutionSelected"
  />
</template>

<script setup>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import {
  applyPointOfInterest,
} from '@shared/http/api'
import InstitutionsIndex from '@extranet/components/resources/institution_employee/request_form/institutions/InstitutionsIndex.vue'

const emits = defineEmits([
  'applied',
])

const store = useStore()
const { t } = useI18n()

function handleInstitutionSelected(resource) {
  store.commit('spinner/ENABLE')

  applyPointOfInterest(resource.id)
    .then((response) => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('institutions_employees.apply.success'),
          type: 'success',
        },
      )

      emits('applied', response.data.data)
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}
</script>
