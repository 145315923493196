import { computed } from 'vue'

export default function useInstitutionEmployee(props) {
  const pointOfInterest = computed(() => (
    props.resource.relationships?.point_of_interest
  ))

  const user = computed(() => (
    props.resource.relationships?.user
  ))

  const state = computed(() => (
    props.resource.attributes?.state
  ))

  return {
    state,
    pointOfInterest,
    user,
  }
}
